// post login 
export const POST_LOGIN_DATA_SUCCESS = "POST_LOGIN_DATA_SUCCESS";
export const POST_LOGIN_DATA_FAILURE = "POST_LOGIN_DATA_FAILURE";

//get certificate
export const GET_CERTIFICATE_DATA_SUCCESS = "GET_CERTIFICATE_DATA_SUCCESS";
export const GET_CERTIFICATE_DATA_FAILURE = "GET_CERTIFICATE_DATA_FAILURE";

//view certificate by id
export const VIEW_CERTIFICATE_DATA_SUCCESS = "VIEW_CERTIFICATE_DATA_SUCCESS";
export const VIEW_CERTIFICATE_DATA_FAILURE = "VIEW_CERTIFICATE_DATA_FAILURE";

//Add certificate
export const POST_CERTIFICATE_DATA_SUCCESS = "POST_CERTIFICATE_DATA_SUCCESS";
export const POST_CERTIFICATE_DATA_FAILURE = "POST_CERTIFICATE_DATA_FAILURE";

//Edit certificate
export const PUT_CERTIFICATE_DATA_SUCCESS = "PUT_CERTIFICATE_DATA_SUCCESS";
export const PUT_CERTIFICATE_DATA_FAILURE = "PUT_CERTIFICATE_DATA_FAILURE";

//Delete certificate
export const DELETE_CERTIFICATE_DATA_SUCCESS = "DELETE_CERTIFICATE_DATA_SUCCESS";
export const DELETE_CERTIFICATE_DATA_FAILURE = "DELETE_CERTIFICATE_DATA_FAILURE";

// Upload file
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS'
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE'

// find Report

export const FIND_REPORT_SUCCESS = 'FIND_REPORT_SUCCESS'
export const FIND_REPORT_FAILURE = 'FIND_REPORT_FAILURE'